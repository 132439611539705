import ConsultantFormF1 from '@/components/consultant/ConsultantFormF1'
import ConsultantFormTriggerF1 from "@/components/consultant/ConsultantFormTriggerF1"
import { use, registerComponent } from '@/utils/plugins'

/**
 * глобальная регистрация компонента
 * после этого компонент можно использовать
 * везде в приложении
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, ConsultantFormF1)
  }
}

use(Plugin)

export default Plugin

/**
 * экспорт компонента
 */
export {
  ConsultantFormF1,
  ConsultantFormTriggerF1
}
