<template lang="pug">
  .platform-consultant-form.section: validation-observer(v-slot="{valid, invalid, handleSubmit}" ref="observer")
    .form-header(ref="header" v-if="isNotSuccess")
      p.title.is-5.mb-6(ref="title") Получить консультацию
    .form-messages(ref="messages")
      // отображение сообщения основано на комбинации/изменении статусов
      // (также можно использовать b-message вместо b-notification)
      b-notification(type="is-danger" @close="onCloseNotification" has-icon v-model="status.error")
        p Ошибка! Не удалось отправить сообщение.
        p Попробуйте снова, если ошибка повторяется, обновите страницу.
        // можно убрать подробный вывод ошибок
        p Описание ошибки:
        template(v-for="error in errors")
          strong {{error}}
      b-notification(type="is-success" @close="onCloseNotification" has-icon v-model="status.success")
        p Спасибо! Ваше сообщение отправлено.
        p Постараемся ответить как можно быстрее.
    .form-wrapper(v-if="isNotSuccess")
      form.form(@submit.prevent.stop="handleSubmit(submit)" ref="form" enctype="multipart/form-data")
        // обязательный атрибут, по этому атрибуту
        // битрикс понимает какая форма отправлена со страницы
        input(type="hidden" name="WEB_FORM_ID" :value="formId")
        // имена полей уникальны в одной установке Битрикс
        // Битрикс полчает список полей по ID формы и затем
        // выбирает поля конкретной формы из объекта $_REQUEST
        // Ф.И.О
        validation-provider(v-slot="v" rules="required" name="Ваше имя" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(:type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_text_55" placeholder="Ваше имя*" v-model="formData.name")
          
        // Телефон
        validation-provider(v-slot="v" rules="required" name="Телефон" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3
            b-input(name="form_text_54" placeholder="Ваш номер телефона*" v-model="formData.phone")
          
        // Email
        validation-provider(v-slot="v" rules="required|email" name="Email" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(:type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_email_53" placeholder="Email*" v-model="formData.email")
      
        //Интересующий Вас вопрос  
        validation-provider(v-slot="v" rules="required" name="Интересующий Вас вопрос" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(label="Интересующий Вас вопрос:")
            b-select(v-model="formData.service" expanded name="form_text_57")
              option(v-for="option in services" :selected="option.selected" :key="option.id" :value="option.name") {{option.name}}
              
        // Сообщение
        validation-provider(v-slot="v" rules="max:1000" slim name="Комментарий...")
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(type="{'is-danger': v.invalid}")
            b-input(name="form_textarea_52" placeholder="Комментарий..." type="textarea"
              v-model="formData.message")
        b-message(style="margin-top: 2rem;"): p.help.
          Отправляя сообщение вы соглашаетесь <a target="_blank" href="/agreement">с условиями</a>. Если вы не
          согласны <a target="_blank" href="/agreement">с условиями
          пользовательского соглашения</a>, не отправляйте форму.
          Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.

        .controls: b-button.is-fullwidth(type="is-dark"
              native-type="submit"
              @click.prevent="submit"
              :disabled="invalid"
              :class="{'is-loading': isLock}") Отправить
</template>
<script>
import FormCommonMixin from '@/platform/mixins/form-common-mixin'

/**
 * @deprecated use mixins
 */
export default {
  mixins: [FormCommonMixin],
  data () {
    return {
      formId: 6,
      services: [
        { id: 1, name: 'Бухгалтерский аутсорсинг', active: true },
        { id: 2, name: 'Ликвидация и банкротство', active: true },
        { id: 3, name: 'Регистрация', active: true },
        { id: 4, name: 'Юридические услуги', active: true },
        { id: 5, name: 'Налоговые проверки', active: true },
        { id: 6, name: 'Сопровождение деятельности ТСЖ, ТСН, СНТ', active: true },

      ],
    }
  }
}
</script>
<style lang="sass" scoped>
@import "bulma-theme"
.platform-consultant-form.section
  padding: 1.4rem
  +mobile
    background: red
.platform-consultant-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem
</style>
