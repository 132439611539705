import CallbackFormF1 from '@/components/callback/CallbackFormF1'
import CallbackFormTriggerF1 from "@/components/callback/CallbackFormTriggerF1";
import { use, registerComponent } from '@/utils/plugins'

/**
 * глобальная регистрация компонента
 * после этого компонент можно использовать
 * везде в приложении
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, CallbackFormF1)
  }
}

use(Plugin)

export default Plugin

/**
 * экспорт компонента
 */
export {
  CallbackFormF1,
  CallbackFormTriggerF1
}
