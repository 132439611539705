<template lang="pug">
  a.review-question-trigger.button(@click.stop.prevent="openForm" :class="customClass")
    slot(name="title")
      span.button-title {{title}}
    slot(name="icon" v-if="isNotHiddenIcon")
      span.icon: i.fas.fa-comment-dots
</template>
<script>
import FeedbackFormF1 from "@/components/feedback/FeedbackFormF1";
import FormTriggerMixin from "@/platform/mixins/form-trigger-mixin";

export default {
  mixins: [FormTriggerMixin],
  methods: {
    openForm() {
      this.$buefy.modal.open({
        component: FeedbackFormF1,
        width: 640,
        /**
         * всегда добавляем класс,
         * чтобы иметь возможность
         * переопределения стилей
         * для форм в модальном окне
         */
        customClass: 'form-in-modal',
        canCancel: ['x'],
        /**
         * опция используется для определения
         * что форма открыта в модальном окне,
         * это не стандартная опция она добавлена
         * специально в форму, и отслеживается
         * при закрытии уведомления об успешной
         * отправке
         */
        props: {
          trigger: true
        }
      })
    }
  }
}
</script>
