<template lang="pug">
  a.callback-form-trigger-f1.button(@click.stop.prevent="openForm" :class="customClass")
    slot(name="title")
      span.button-title {{title}}
    slot(name="icon" v-if="isNotHiddenIcon")
      span.icon: i.fas.fa-comment-dots
</template>
<script>
import CallbackFormF1 from "@/components/callback/CallbackFormF1";
import FormTriggerMixin from "@/platform/mixins/form-trigger-mixin";

export default {
  mixins: [FormTriggerMixin],
  methods: {
    openForm() {
      console.log('trig callback');
      this.$buefy.modal.open({
        component: CallbackFormF1,
        width: 640,
        customClass: 'form-in-modal',
        canCancel: ['x'],
        /**
         * опция используется для определения
         * что форма открыта в модальном окне,
         * это не стандартная опция она добавлена
         * специально в форму, и отслеживается
         * при закрытии уведомления об успешной
         * отправке
         */
        props: {
          trigger: true
        }
      })
    }
  }
}
</script>
