<template lang="pug">
  .platform-feedback-form: validation-observer(v-slot="{valid, invalid, handleSubmit}" ref="observer")
    .form-header(ref="header" v-if="isNotSuccess")
      p.title.is-5.mb-6(ref="title") Обратиться к консультантам
    .form-messages(ref="messages")
      // отображение сообщения основано на комбинации/изменении статусов
      // (также можно использовать b-message вместо b-notification)
      b-notification(type="is-danger" @close="onCloseNotification" has-icon v-model="status.error")
        p Ошибка! Не удалось отправить сообщение.
        p Попробуйте снова, если ошибка повторяется, обновите страницу.
        // можно убрать подробный вывод ошибок
        p Описание ошибки:
        template(v-for="error in errors")
          strong {{error}}
      b-notification(type="is-success" @close="onCloseNotification" has-icon v-model="status.success")
        p Спасибо! Ваше сообщение отправлено.
        p Постараемся ответить как можно быстрее.
    .form-wrapper(v-if="isNotSuccess")
      form.form(@submit.prevent.stop="handleSubmit(submit)" ref="form" enctype="multipart/form-data")
        // обязательный атрибут, по этому атрибуту
        // битрикс понимает какая форма отправлена со страницы
        input(type="hidden" name="WEB_FORM_ID" :value="formId")
        // имена полей уникальны в одной установке Битрикс
        // Битрикс полчает список полей по ID формы и затем
        // выбирает поля конкретной формы из объекта $_REQUEST
        // Ф.И.О
        validation-provider(v-slot="v" rules="required" name="Ваше имя" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(:type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_text_41" placeholder="Ваше имя*" v-model="formData.name")
          
        // Телефон
        validation-provider(v-slot="v" rules="required" name="Телефон" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3
            b-input(name="form_text_38" placeholder="Ваш номер телефона*" v-model="formData.phone")
          
        // Email
        validation-provider(v-slot="v" rules="required|email" name="Email" slim)
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(:type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_email_2" placeholder="Email*" v-model="formData.email")
        // Сообщение
        validation-provider(v-slot="v" rules="required|max:1000" slim name="Сообщение")
          p.has-text-danger.is-size-7 {{v.errors[0]}}
          b-field.mb-3(type="{'is-danger': v.invalid}")
            b-input(name="form_textarea_1" placeholder="Cообщение или вопрос*..." type="textarea"
              v-model="formData.message")
        b-message(style="margin-top: 2rem;"): p.help.
          Отправляя сообщение вы соглашаетесь <a target="_blank" href="/agreement">с условиями</a>. Если вы не
          согласны <a target="_blank" href="/agreement">с условиями
          пользовательского соглашения</a>, не отправляйте форму.
          Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.

        .controls: b-button.is-fullwidth(type="is-dark"
              native-type="submit"
              @click.prevent="submit"
              :disabled="invalid"
              :class="{'is-loading': isLock}") Отправить
</template>
<script>
import FormCommonMixin from '@/platform/mixins/form-common-mixin'

export default {
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 1,
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'bulma-theme'
// common
.platform-feedback-form
  background: #fff


// in modal
.form-in-modal .platform-feedback-form
  .form-wrapper
    background: #fff
    padding: 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>
