import $ from 'jquery'
import Vue from 'vue'
import store from '@/store'
//import router from '../router'
import { ConsultantFormF1 } from "@/components"

import { gsap, TweenMax, TimelineMax } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(CSSRulePlugin, CSSPlugin, ScrollToPlugin, ScrollTrigger)

import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
// import * as indicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

/**
 * управление поведением `navbar`
 *
 * компонент: /local/components/platform/navbar
 *
 * Основная логика в том, что Vue "оборачивает"
 * существующий DOM-элемент. Это дает возможность
 * добавлять любой функционал в панель. Например добавлять
 * кнопки отрытия модальных окон с формами.
 *
 * Также функциональность самой панели находиться
 * в методах и "состоянии" Vue.
 *
 * Связь с общим хранилищем отрывает "безграничные" возможности
 * интеграции, например создание "корзины заказов".
 *
 * В новой версии вместо ScrollMagic используется gsap.scrollTrigger.
 * @see https://greensock.com/docs/v3/Plugins/ScrollTrigger
 */

function start () {
  /* eslint no-unused-vars: 0 */
  /**
   * подразумевается что на странице
   * только один элемент nav#navbar-top
   *
   * если необходимо инициализировать несколько панелей, то
   * надо использовать отдельные файлы/модули или инициализировать
   * панели в цикле (for)
   */
  const app = new Vue({
    store,
    //router,
    el: 'nav#navbar',
    data() {
      return {
        active: false
      }
    },
    mounted() {
      const vm = this
      $('.navbar-dropdown-parent.js-dropdown ').on('click', function(e){
        e.preventDefault();
        let $drop = $('.navbar-dropdown', $(this).parent())
        if ($drop.is(':visible')) {
          $drop.hide()
          // TweenMax.to($drop, {alpha: 0, y: -100, duration: .2, display: 'none'})
        } else {
          $drop.show()
          // TweenMax.to($drop, {alpha: 1, display: 'block', y: 0, duration: .2})
        }

        return false
      })
    },
    methods: {
      /**
       * добавление класса
       * к меню при клике на `burger`
       */
      toggle() {
        this.active = !this.active
        const elemHtml = document.getElementsByTagName( 'html' )[0];
        elemHtml.classList.toggle("is-clipped");
      },
     consultantform () {
        /**
         * лучший вариант использовать
         * отдельные компоненты в модальном окне
         */
        const modal = this.$buefy.modal.open({
          parent: this,
          component: ConsultantFormF1,
          canCancel: ['x'],
          width: 720
        }) 

        /**
         * после того, как данные успешно отправлены
         * на сервер, необходимо скрыть кнопку закрытия окна
         * вынудив пользователя нажать кнопку "ok", чтобы
         * явно сбросить настройки формы.
         *
         * Другой вариант полностью разрушать форму
         * при закрытии destroyOnHide: true
         */
        modal.$on('success', () => {
           modal.$props.canCancel = false
        })
      }
    }
  })

  /**
   * scrollmagic
   *
   * для запуска функциональности добавить к nav.navbar
   * класс `navbar-sticky`. после прикрепления панели
   * добавляется класс `is-pinned`
   *
   */
  let sm_selector = '.navbar-sticky'
  let pinnedClass = 'is-pinned'
  const zIndex = 39
  const showIndicators =  false

  let navbar = document.querySelector(sm_selector)

  if (!navbar) return

  const controller = new ScrollMagic.Controller({})
  const menuSceneOffset = 150
  const menuScene = new ScrollMagic.Scene({
    triggerElement: sm_selector,
    triggerHook: 'onLeave',
    offset: menuSceneOffset,
    duration: 0
  })
    .setClassToggle(sm_selector, pinnedClass)
    .setPin(sm_selector)
    .on('enter', function(e) {
      TweenMax.to(sm_selector, 0.3, {
        duration: 0.3,
        y: menuSceneOffset - 2,
        zIndex: zIndex
      })
    })
    .on('leave', function(e) {
      TweenMax.to(sm_selector, 0.2, {
        y: 0,
        zIndex: zIndex
      })
    })

  if (showIndicators) {
    menuScene.addIndicators({
      name: 'STICKY',
      colorStart: 'green',
      colorEnd: 'red',
      colorTrigger: 'blue',
      indent: menuSceneOffset
    })
  }

  // async init
  setTimeout(function(){
    menuScene.addTo(controller)
  }, 100)

  /**
   *
   * @deprecated не работает, использовать ScrollMagic
   *
   * пример прикрепления меню
   * (в данном случае нельзя анимировать панель)
   *
   * чтобы создавать сложную анимацию,
   * надо использовать "обертки" (.trigger > .navbar), прикреплять
   * их, анимировать элементы внутри через
   * `timeline` или `tween`.
   *
   * @todo scrollTrigger "убивает" функциональность Vue
   */
  ScrollTrigger.create({
    trigger: '#no-element',
    /**
     * для меню обычно используем
     * элемент внизу страницы, .footer
     * подойдет (если он есть), докрутить
     * до него не получиться и значит меню
     * будет всегда "прибито" сверху
     *
     * если нужно другое поведение, нет
     * проблем, читаем документацию.
     *
     * @see https://greensock.com/docs/v3/Plugins/ScrollTrigger
     *
     */
    endTrigger: '.footer',
    /**
     * Место (точка) для срабатывания триггера
     *
     * строка - "x y", где x - триггер, y - scroller
     *
     * Например
     *
     * "bottom center" -
     *
     * top - по верхнему краю
     * bottom - по нижнему краю
     * center - по центру элемента
     * +=N, -=N - поля
     */
    start: "+=300",
    end: 'bottom',
    markers: false,
    /**
     *
     */
    pinSpacing: false,
    /**
     * прикрепляем элемент
     * (если меню наверху, то оно будет прикреплено сразу)
     */
    pin: true,
    pinReparent: true,
    toggleClass: 'is-pinned'
  })

}

export default {
  start
}
