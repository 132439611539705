import FeedbackFormF1 from "@/components/feedback/FeedbackFormF1"
import FeedbackFormTriggerF1 from "@/components/feedback/FeedbackFormTriggerF1"
import { use, registerComponent } from "@/utils/plugins"

/**
 * При глобальной регистрации компонента
 * будет доступен "глобально" на уровне Vue,
 * то есть использовать <feedback-form></feedback-form>
 * в любых Vue компонентах.
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, FeedbackFormF1)
  }
}

/**
 * use проверяет window.Vue и
 * регистрирует компонент
 */
use(Plugin)

export default Plugin

/**
 * Так же экспортируем компонент
 */
export {
  FeedbackFormF1,
  FeedbackFormTriggerF1
}
